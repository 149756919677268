import { Injectable, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { DataService } from './data.service';
import _ from 'lodash';

import { IGlobal } from '../_models/global.model';
import { KendoSettingsService } from './kendo-settings.service';
import { process } from '@progress/kendo-data-query';
import { DashboardService } from './dashboard.service';
import { Global } from '../_constants/global.variables';
import { MapsService } from './maps.service';
import { ITag } from '../_models/tag.model';

declare const google: any;

@Injectable({
	providedIn: 'root',
})
export class GseService implements OnInit {
	fullDataCacheSubscription: any;
	legendIcons: {
		normal: { name: string; icon: string };
		driving: { name: string; icon: string };
		warning: { name: string; icon: string };
		alarm: { name: string; icon: string };
		critical: { name: string; icon: string };
	};
	legendIconsUpdated: {
		normal: { name: string; icon: string };
		driving: { name: string; icon: string };
		warning: { name: string; icon: string };
		alarm: { name: string; icon: string };
		critical: { name: string; icon: string };
		informationUser: { name: string; icon: string };
		warningUser: { name: string; icon: string };
		alarmUser: { name: string; icon: string };
		criticalUser: { name: string; icon: string };
	};
	legendIconsBreadcrumbs: {
		normal: { name: string; icon: string };
		warning: { name: string; icon: string };
		alarm: { name: string; icon: string };
		critical: { name: string; icon: string };
		start: { name: string; icon: string };
		end: { name: string; icon: string };
	};
	legendIconsBreadcrumbsVocational: { normal: { name: string; icon: string; }; amber: { name: string; icon: string; }; alert: { name: string; icon: string; }; red: { name: string; icon: string; }; start: { name: string; icon: string; }; end: { name: string; icon: string; }; };
	constructor(
		private dataService: DataService,
		private kendoSettingsService: KendoSettingsService,
		private dashboardService: DashboardService,
		private mapsService: MapsService
	) {
		if (
			Global.FullDataCacheExists !== undefined &&
			!Global.FullDataCacheExists
		) {
			this.fullDataCacheSubscription =
				this.dataService.fullDataCacheExists$.subscribe((data: any) => {
					if (data === true) {
						this.initialize();
						this.fullDataCacheSubscription.unsubscribe();
					}
				});
		} else {
			this.initialize();
		}
	}
	public global: IGlobal = Global;

	public updateAssetImageEmitter$ = new Subject<any>();
	public userDefinedJbtStandardObservationIds = [
		56442, 56443, 56444, 56445, 56446, 56447, 56448, 56449, 56450, 56451,
		56452, 56453, 56454, 56455, 56456, 56457, 56458, 56459, 56460, 56461,
	];

	public legendSpeedsBreadcrumbs = {
		slow: {
			name: '0-5 kph',
			color: '#54A9FE',
		},
		faster: {
			name: '5-15 kph',
			color: '#82C91E',
		},
		fast: {
			name: '15-25 kph',
			color: '#FACA48',
		},
		fastest: {
			name: '25 + kph',
			color: '#D32020',
		},
	};

	ngOnInit(): void {}

	initialize() {
		this.legendIcons = {
			normal: {
				name: 'Normal',
				icon:
					Global.imagesUrl +
					'assets/images/GSEGraphics/iCON_Base_65x65_Gray.png',
			},
			driving: {
				name: 'On/Running',
				icon:
					Global.imagesUrl +
					'assets/images/GSEGraphics/iCON_Base_65x65_Green.png',
			},
			warning: {
				name: 'Warning',
				icon:
					Global.imagesUrl +
					'assets/images/GSEGraphics/iCON_Base_65x65_Yellow.png',
			},
			alarm: {
				name: 'Alarm',
				icon:
					Global.imagesUrl +
					'assets/images/GSEGraphics/iCON_Base_65x65_Red.png',
			},
			critical: {
				name: 'Critical (Bouncing)',
				icon:
					Global.imagesUrl +
					'assets/images/GSEGraphics/iCON_Base_65x65_Red.png',
			},
		};

		this.legendIconsUpdated = {
			normal: {
				name: 'Normal',
				icon:
					Global.imagesUrl +
					'assets/images/GSEGraphics/No Tail-Pushback Tractor-Off.svg',
			},
			driving: {
				name: 'On/Running',
				icon:
					Global.imagesUrl +
					'assets/images/GSEGraphics/No Tail-Pushback Tractor-On.svg',
			},
			warning: {
				name: 'Warning',
				icon:
					Global.imagesUrl +
					'assets/images/GSEGraphics/No Tail-Pushback Tractor-Warn.svg',
			},
			alarm: {
				name: 'Alarm',
				icon:
					Global.imagesUrl +
					'assets/images/GSEGraphics/No Tail-Pushback Tractor-Alarm.svg',
			},
			critical: {
				name: 'Critical',
				icon:
					Global.imagesUrl +
					'assets/images/GSEGraphics/No Tail-Pushback Tractor-Alarm.svg',
			},
			informationUser: {
				name: 'Informational User Defined Alert',
				icon:
					Global.imagesUrl +
					'assets/images/GSEGraphics/User-Alarm-Informational.svg',
			},
			warningUser: {
				name: 'Warning User Defined Alert',
				icon:
					Global.imagesUrl +
					'assets/images/GSEGraphics/User-Alarm-Warning.svg',
			},
			alarmUser: {
				name: 'Fault User Defined Alert',
				icon:
					Global.imagesUrl +
					'assets/images/GSEGraphics/User-Alarm-Alarm.svg',
			},
			criticalUser: {
				name: 'Critical User Defined Alert',
				icon:
					Global.imagesUrl +
					'assets/images/GSEGraphics/User-Alarm-Critical.svg',
			},
		};

		this.legendIconsBreadcrumbsVocational = {
			normal: {
				name: 'Normal',
				icon:	this.mapsService.returnPlaybackMarker(),
			},
			alert: {
				name: 'Alert',
				icon:
					Global.imagesUrl +
					'assets/images/GSEGraphics/Cir-Alert.svg',
			},
			amber: {
				name: 'Amber',
				icon:
					Global.imagesUrl +
					'assets/images/GSEGraphics/Cir-Amber.svg',
			},
			red: {
				name: 'Red',
				icon:
					Global.imagesUrl +
					'assets/images/GSEGraphics/Cir-Critical.svg',
			},
			start: {
				name: 'Start',
				icon: 'http://maps.google.com/mapfiles/ms/micons/green.png',
			},
			end: {
				name: 'End',
				icon: 'http://maps.google.com/mapfiles/ms/micons/red.png',
			},
		}

		this.legendIconsBreadcrumbs = {
			normal: {
				name: 'Normal',
				icon:	this.mapsService.returnPlaybackMarker(),
			},
			warning: {
				name: 'Warning',
				icon:
					Global.imagesUrl +
					'assets/images/GSEGraphics/Cir-Warning.svg',
			},
			alarm: {
				name: 'Alarm',
				icon:
					Global.imagesUrl +
					'assets/images/GSEGraphics/Cir-Alarm.svg',
			},
			critical: {
				name: 'Critical',
				icon:
					Global.imagesUrl +
					'assets/images/GSEGraphics/Cir-Critical.svg',
			},
			start: {
				name: 'Start',
				icon: 'http://maps.google.com/mapfiles/ms/micons/green.png',
			},
			end: {
				name: 'End',
				icon: 'http://maps.google.com/mapfiles/ms/micons/red.png',
			},
		};
	}

	updateAssetImageAcrossWidgets(asset) {
		this.updateAssetImageEmitter$.next(asset);
	}

	determineAssetImageFromAssetTypeForMap(asset) {
		// console.log(asset);
		// console.log(this.dataService.cache.assets);
		// AssetTypeId
		//AssetTypeName

		let currentStateObject: any;
		currentStateObject = this.returnCurrentAlarmState(asset, 'equipment');
		let colorAndFileTypeString = '';
		if (
			asset.AssetTypeName === 'Pushback' ||
			asset.AssetTypeName === 'Loader' ||
			asset.AssetTypeName === 'Deicer'
		) {
			if (currentStateObject.currentState === 'Alarm') {
				colorAndFileTypeString = '-Alarm.svg';
			} else if (currentStateObject.currentState === 'Critical') {
				colorAndFileTypeString = '-Alarm.svg';
			} else if (currentStateObject.currentState === 'Warning') {
				colorAndFileTypeString = '-Warn.svg';
			} else if (currentStateObject.currentState === 'Running') {
				colorAndFileTypeString = '-On.svg';
			} else {
				colorAndFileTypeString = '-Off.svg';
			}
			//below variable would be equal to asset.assetTypeId or whatever mark says.

			let baseImageURL = '';
			if (asset.AssetTypeName === 'Pushback') {
				baseImageURL =
					Global.imagesUrl +
					'assets/images/GSEGraphics/Pushback Tractor';
			} else if (asset.AssetTypeName === 'Loader') {
				baseImageURL =
					Global.imagesUrl + 'assets/images/GSEGraphics/Loader';
			} else if (asset.AssetTypeName === 'Deicer') {
				baseImageURL =
					Global.imagesUrl + 'assets/images/GSEGraphics/Deicers';
			}
			if (baseImageURL === '') {
				return {};
			} else {
				return {
					alarmType: currentStateObject.currentState,
					icon: {
						url: baseImageURL + colorAndFileTypeString,
						scaledSize: new google.maps.Size(50, 50),
						origin: new google.maps.Point(0, 0), // set the base origin
						anchor: new google.maps.Point(25, 50), // set the base anchor
					},
				};
			}
		}else if (asset.AssetTypeName === 'Refuse Truck' || asset.AssetTypeName === 'Fire Truck'){
			if(asset.AssetTypeName === 'Refuse Truck'){
				return {
					alarmType: currentStateObject.currentState,
					// icon: {
					// 	url: Global.imagesUrl + 'assets/images/VocationalGraphics/RefuseFrontView_v2.png',
					// 	scaledSize: new google.maps.Size(50, 50),
					// 	origin: new google.maps.Point(0, 0), // set the base origin
					// 	anchor: new google.maps.Point(25, 50), // set the base anchor
					// },
				};
			} else if(asset.AssetTypeName === 'Fire Truck'){
				return {
					alarmType: currentStateObject.currentState,
					// icon: {
					// 	url: Global.imagesUrl + 'assets/images/VocationalGraphics/FE_Front.png',
					// 	scaledSize: new google.maps.Size(50, 50),
					// 	origin: new google.maps.Point(0, 0), // set the base origin
					// 	anchor: new google.maps.Point(25, 50), // set the base anchor
					// },
				};
			}
		}

		else {
			if (currentStateObject.currentState === 'Alarm') {
				colorAndFileTypeString = '_Red.png';
			} else if (currentStateObject.currentState === 'Critical') {
				colorAndFileTypeString = '_Red.png';
			} else if (currentStateObject.currentState === 'Warning') {
				colorAndFileTypeString = '_Yellow.png';
			} else if (currentStateObject.currentState === 'Running') {
				colorAndFileTypeString = '_Green.png';
			} else {
				colorAndFileTypeString = '_Gray.png';
			}
			//below variable would be equal to asset.assetTypeId or whatever mark says.
			if (asset.AssetModelId !== 0) {
				let assetFromLocalArray =
					this.dataService.cache.assetModels.find((assetModel) => {
						return assetModel.Id === asset.AssetModelId;
					});
				return {
					alarmType: currentStateObject.currentState,
					icon: {
						url:
						Global.imagesUrl + assetFromLocalArray.BaseImageURL +
							colorAndFileTypeString,
						origin: new google.maps.Point(0, 0), // set the base origin
						anchor: new google.maps.Point(25, 50), // set the base anchor
					},
				};
			} else {
				return {};
			}
		}

		// console.log(createdString);
		// return createdString;
	}

	determineUserDefinedAlarmsImageFromAssetTypeForTable(
		asset,
		shouldReturnListOfCurrentAlarms?
	) {
		let currentStateObject: any;
		if (shouldReturnListOfCurrentAlarms === undefined) {
			currentStateObject = this.returnCurrentAlarmState(asset, 'user');
		} else if (shouldReturnListOfCurrentAlarms === true) {
			currentStateObject = this.returnCurrentAlarmState(
				asset,
				'user',
				true
			);
		}

		let colorAndFileTypeString = '';

		if (currentStateObject.currentState === 'Alarm') {
			colorAndFileTypeString = '-Alarm.svg';
		} else if (currentStateObject.currentState === 'Critical') {
			colorAndFileTypeString = '-Critical.svg';
		} else if (currentStateObject.currentState === 'Warning') {
			colorAndFileTypeString = '-Warning.svg';
		} else if (currentStateObject.currentState === 'Informational') {
			colorAndFileTypeString = '-Informational.svg';
		}

		let baseImageURL =
			Global.imagesUrl + 'assets/images/GSEGraphics/User-Alarm';
		let returnObject: any = {
			alarmType: currentStateObject.currentState,
			icon: {
				url: baseImageURL + colorAndFileTypeString,
				scaledSize: new google.maps.Size(50, 50),
				origin: new google.maps.Point(0, 0), // set the base origin
				anchor: new google.maps.Point(25, 50), // set the base anchor
			},
		};

		if (shouldReturnListOfCurrentAlarms === true) {
			returnObject.currentListOfAlarms =
				currentStateObject.listOfTagAlarms;
		}
		return returnObject;
	}

	determineAssetImageFromAssetTypeForTable(asset) {
		// console.log(asset);
		// console.log(this.dataService.cache.assets);
		// AssetTypeId
		//AssetTypeName

		let currentStateObject = this.returnCurrentAlarmState(
			asset,
			'equipment'
		);

		let colorAndFileTypeString = '';
		if (
			asset.AssetTypeName === 'Pushback' ||
			asset.AssetTypeName === 'Loader' ||
			asset.AssetTypeName === 'Deicer'
		) {
			if (currentStateObject.currentState === 'Alarm') {
				colorAndFileTypeString = '-Alarm.svg';
			} else if (currentStateObject.currentState === 'Critical') {
				colorAndFileTypeString = '-Alarm.svg';
			} else if (currentStateObject.currentState === 'Warning') {
				colorAndFileTypeString = '-Warn.svg';
			} else if (currentStateObject.currentState === 'Running') {
				colorAndFileTypeString = '-On.svg';
			} else {
				colorAndFileTypeString = '-Off.svg';
			}
			//below variable would be equal to asset.assetTypeId or whatever mark says.

			let baseImageURL = '';
			if (asset.AssetTypeName === 'Pushback') {
				baseImageURL =
					Global.imagesUrl +
					'assets/images/GSEGraphics/No Tail-Pushback Tractor';
			} else if (asset.AssetTypeName === 'Loader') {
				baseImageURL =
					Global.imagesUrl +
					'assets/images/GSEGraphics/No Tail-Loader';
			} else if (asset.AssetTypeName === 'Deicer') {
				baseImageURL =
					Global.imagesUrl +
					'assets/images/GSEGraphics/No Tail-Deicers';
			}
			if (baseImageURL === '') {
				return {};
			} else {
				return {
					alarmType: currentStateObject.currentState,
					icon: {
						url: baseImageURL + colorAndFileTypeString,
						scaledSize: new google.maps.Size(50, 50),
						origin: new google.maps.Point(0, 0), // set the base origin
						anchor: new google.maps.Point(25, 50), // set the base anchor
					},
				};
			}
		}else if (asset.AssetTypeName === 'Refuse Truck' || asset.AssetTypeName === 'Fire Truck'){
			if(asset.AssetTypeName === 'Refuse Truck'){
				return {
					alarmType: currentStateObject.currentState,
					icon: {
						url: Global.imagesUrl + 'assets/images/VocationalGraphics/RefuseFrontView_v2.png',
						scaledSize: new google.maps.Size(50, 50),
						origin: new google.maps.Point(0, 0), // set the base origin
						anchor: new google.maps.Point(25, 50), // set the base anchor
					},
				};
			} else if(asset.AssetTypeName === 'Fire Truck'){
				return {
					alarmType: currentStateObject.currentState,
					icon: {
						url: Global.imagesUrl + 'assets/images/VocationalGraphics/FE_Front.png',
						scaledSize: new google.maps.Size(50, 50),
						origin: new google.maps.Point(0, 0), // set the base origin
						anchor: new google.maps.Point(25, 50), // set the base anchor
					},
				};
			}
		}

		else {
			if (currentStateObject.currentState === 'Alarm') {
				colorAndFileTypeString = '_Red.png';
			} else if (currentStateObject.currentState === 'Critical') {
				colorAndFileTypeString = '_Red.png';
			} else if (currentStateObject.currentState === 'Warning') {
				colorAndFileTypeString = '_Yellow.png';
			} else if (currentStateObject.currentState === 'Running') {
				colorAndFileTypeString = '_Green.png';
			} else {
				colorAndFileTypeString = '_Gray.png';
			}
			//below variable would be equal to asset.assetTypeId or whatever mark says.
			if (asset.AssetModelId !== 0) {
				let assetFromLocalArray =
					this.dataService.cache.assetModels.find((assetModel) => {
						return assetModel.Id === asset.AssetModelId;
					});
				return {
					alarmType: currentStateObject.currentState,
					icon: {
						url:
						Global.imagesUrl + assetFromLocalArray.BaseImageURL +
							colorAndFileTypeString,
						origin: new google.maps.Point(0, 0), // set the base origin
						anchor: new google.maps.Point(25, 50), // set the base anchor
					},
				};
			} else {
				return {};
			}
		}

		// console.log(createdString);
		// return createdString;
	}

	// determineAssetImageFromAssetType(asset) {
	// 	// console.log(asset);
	// 	// console.log(this.dataService.cache.assets);
	// 	// AssetTypeId

	// 	let currentState = "";
	// 	currentState = this.returnCurrentAlarmState(asset);
	// 	let colorAndFileTypeString = "";

	// 	if (currentState === "Alarm") {
	// 		colorAndFileTypeString = "_Red.png";
	// 	} else if (currentState === "Critical") {
	// 		colorAndFileTypeString = "_Red.png";
	// 	} else if (currentState === "Warning") {
	// 		colorAndFileTypeString = "_Yellow.png";
	// 	} else if (currentState === "Running") {
	// 		colorAndFileTypeString = "_Green.png";
	// 	} else {
	// 		colorAndFileTypeString = "_Gray.png";
	// 	}
	// 	//below variable would be equal to asset.assetTypeId or whatever mark says.

	// 	if (asset.AssetModelId !== 0) {
	// 		let assetFromLocalArray = this.dataService.cache.assetModels.find((assetModel) => {
	// 			return assetModel.Id === asset.AssetModelId;
	// 		});

	// 		return {
	// 			alarmType: currentState,
	// 			stringPath: assetFromLocalArray.BaseImageURL + colorAndFileTypeString,
	// 		};
	// 	} else {
	// 		return {};
	// 	}

	// 	// console.log(createdString);
	// 	// return createdString;
	// }

	returnCurrentAlarmState(
		asset,
		alarmsType: string,
		returnItemsInList?: boolean
	) {
		let currentState = '';
		if (alarmsType === 'equipment') {
			if (asset !== undefined) {
				asset.Tags.forEach((tag) => {
					//here we need to make sure the jbt Standard ObvervationId of the tag is not in the list of user defined ones
					if (
						!_.isNil(tag.JBTStandardObservation) &&
						!this.userDefinedJbtStandardObservationIds.includes(
							parseInt(tag.JBTStandardObservation.Id)
						)
					) {
						if (_.isNil(tag)) {
							return false;
						} else if (
							!_.isNil(tag.ValueWhenActive) &&
							!_.isNil(tag.Value)
						) {
							if (tag.Value == tag.ValueWhenActive) {
								if (
									tag.IsCritical === true &&
									tag.Value != '' &&
									tag.Value !== null &&
									tag.Value !== undefined
								) {
									currentState = 'Critical';
								} else if (
									tag.IsAlarm === true &&
									tag.Value != '' &&
									tag.Value !== null &&
									tag.Value !== undefined &&
									currentState !== 'Critical'
								) {
									currentState = 'Alarm';
								} else if (
									tag.IsWarning === true &&
									tag.Value != '' &&
									tag.Value !== null &&
									tag.Value !== undefined &&
									currentState !== 'Critical' &&
									currentState !== 'Alarm'
								) {
									currentState = 'Warning';
								} else if (
									!_.isNil(tag.JBTStandardObservation)
								) {
									if (
										tag.JBTStandardObservation
											.JBTStandardObservationSeverityLevelId ===
											3 &&
										tag.Value != '' &&
										tag.Value !== null &&
										tag.Value !== undefined
									) {
										currentState = 'Critical';
									} else if (
										tag.JBTStandardObservation
											.JBTStandardObservationSeverityLevelId ===
											1 &&
										tag.Value != '' &&
										tag.Value !== null &&
										tag.Value !== undefined &&
										currentState !== 'Critical'
									) {
										currentState = 'Alarm';
									} else if (
										tag.JBTStandardObservation.Id ===
											4504 &&
										tag.Value != '' &&
										tag.Value !== null &&
										tag.Value !== undefined &&
										currentState !== 'Critical' &&
										currentState !== 'Alarm' &&
										currentState !== 'Warning'
									) {
										//console.log('Running state tag:', tag);
										currentState = 'Running';
									}
								}
							}
						}
					}
				});
				return { currentState: currentState };
			}
		} else if (alarmsType === 'user') {
			if (asset !== undefined) {
				let currentUserAlarms = [];
				asset.Tags.forEach((tag) => {
					//here we need to make sure the jbt Standard ObvervationId of the tag is in the list of user defined ones
					if (
						!_.isNil(tag.JBTStandardObservation) &&
						this.userDefinedJbtStandardObservationIds.includes(
							parseInt(tag.JBTStandardObservation.Id)
						)
					) {
						if (
							tag.IsCritical === true &&
							tag.Value != '0' &&
							tag.Value != '' &&
							!_.isNil(tag.Value)
						) {
							currentState = 'Critical';
							if (returnItemsInList === true) {
								currentUserAlarms.push(tag);
							}
						} else if (
							tag.IsAlarm === true &&
							tag.Value != '0' &&
							tag.Value != '' &&
							!_.isNil(tag.Value)
						) {
							currentState = 'Alarm';
							if (returnItemsInList === true) {
								currentUserAlarms.push(tag);
							}
						} else if (
							tag.IsWarning === true &&
							tag.Value != '0' &&
							tag.Value != '' &&
							!_.isNil(tag.Value)
						) {
							currentState = 'Warning';
							if (returnItemsInList === true) {
								currentUserAlarms.push(tag);
							}
						} else if (
							tag.IsInformational === true &&
							tag.Value != '0' &&
							tag.Value != '' &&
							!_.isNil(tag.Value)
						) {
							currentState = 'Informational';
							if (returnItemsInList === true) {
								currentUserAlarms.push(tag);
							}
						} else if (!_.isNil(tag.JBTStandardObservation)) {
							if (
								tag.JBTStandardObservation
									.JBTStandardObservationSeverityLevelId ===
									3 &&
								tag.Value != '0' &&
								tag.Value != '' &&
								!_.isNil(tag.Value)
							) {
								currentState = 'Critical';
								if (returnItemsInList === true) {
									currentUserAlarms.push(tag);
								}
							} else if (
								tag.JBTStandardObservation
									.JBTStandardObservationSeverityLevelId ===
									1 &&
								tag.Value != '0' &&
								tag.Value != '' &&
								!_.isNil(tag.Value)
							) {
								currentState = 'Alarm';
								if (returnItemsInList === true) {
									currentUserAlarms.push(tag);
								}
							}
						}
					}
				});
				if (returnItemsInList === true) {
					return {
						currentState: currentState,
						listOfTagAlarms: currentUserAlarms,
					};
				} else {
					return { currentState: currentState };
				}
			}
		}
	}

	createLegendForGseMaps(legendString) {
		var legend = document.getElementById(legendString);
		legend.setAttribute('style', 'border-radius: 5px');
		for (var key in this.legendIconsUpdated) {
			var type = this.legendIconsUpdated[key];
			var name = type.name;
			var icon = type.icon;
			var div = document.createElement('div');
			if (name === 'Critical') {
				div.className = 'bounce-image-container';
			}
			//if name is critical bouncing then margin top is 10 px and we apply a class to have it bounce.
			let nameColor = Global.Theme === 'dark' ? 'white' : 'black';
			div.innerHTML =
				icon ===
				Global.imagesUrl +
					'assets/images/GSEGraphics/User-Alarm-Critical.svg'
					? '<img class="blink-image" style="height: 20px" src="' +
					  icon +
					  '"> ' +
					  "<span class='gseLegendLabel'>" +
					  name +
					  '</span>'
					: name === 'Critical'
					? '<img class="bounce-image bounce-1" style="height: 20px" src="' +
					  icon +
					  '"> ' +
					  "<span class='gseLegendLabel'>" +
					  name +
					  '</span>'
					: '<img style="height: 20px" src="' +
					  icon +
					  '"> ' +
					  "<span class='gseLegendLabel'>" +
					  name +
					  '</span>';
			legend.appendChild(div);
		}
		return legend;
	}

	beginBuildingArrayDataForGseGenericComponents(
		widgetObject,
		assetTypeIds,
		columnDefinitionArray,
		jbtStandardObservationIds,
		gridSettings
	): any {
		return new Promise((resolve, reject) => {
			let assetListForUserSites = [];
			if (!_.isNil(widgetObject.SiteList)) {
				if (typeof widgetObject.SiteList === 'string') {
					widgetObject.SiteList = widgetObject.SiteList.split(
						','
					).map((item) => {
						return parseInt(item);
					});
				}
			}

			assetListForUserSites = this.dataService.cache.assets.filter(
				(asset) => {
					if (asset.Site !== undefined) {
						if (Global.User.isAdmin) {
							return (
								assetTypeIds.includes(asset.AssetTypeId) &&
								asset.Site !== undefined &&
								widgetObject.SiteList.includes(
									parseInt(asset.Site.Id)
								)
							);
						} else {
							return (
								assetTypeIds.includes(asset.AssetTypeId) &&
								asset.Site !== undefined &&
								widgetObject.SiteList.includes(
									parseInt(asset.Site.Id)
								) &&
								Global.User.currentUser.Security &&
								Global.User.currentUser.Security.Aggregate.Collections.SiteIds.includes(
									asset.Site.Id
								)
							);
						}
					}
				}
			);

			if (assetListForUserSites.length === 0) {
				resolve('');
			} else {
				let siteAssetIds: any = assetListForUserSites
					.map((a) => a.Id.toString())
					.join();
				let signalRAssetIds: any = assetListForUserSites
					.map((a: any) => 'A' + a.Id.toString())
					.join(); //-- Asset groups must have the letter 'A' in front of the assetId to join the groups in the SignalR hub. --Kirk T. Sherer, November 8, 2021. ex "A137885,A137886,A137887,A137888,A137890,A137891,A137892,A137893,A137894,A137914,A137915,A137916,A137917,A137931,A137942,A137978,A137990,A137992,A138295,A138296,A138297,A138298,A138305,A138310,A138311,A138312,A138313,A138314,A138315,A138316,A138318,A138321,A138343,A138345,A138346,A138374,A138375,A138376,A138377,A138378,A138403,A138404,A138405,A138406,A138407,A138408,A138409,A138410,A138411,A138412,A138413,A138414,A138417,A138438,A138440,A138441,A138444,A138448,A138449,A138451,A138452,A138453,A138508,A138509,A138510,A138511,A138512,A138513,A138514,A138515,A138521,A138526,A138527,A138528,A138529,A138540,A138541,A138542,A138545,A138556,A138560,A138561,A138562,A138563,A138564,A138565,A138566,A138568,A138579,A138580,A138581,A138582,A138583,A138638,A138639,A138640,A138641,A138644,A138650,A138657,A138675,A138676,A138680,A138684,A138685,A138686,A138700,A138713,A138714,A138717,A138718,A138720,A138724,A138726,A138732,A138735,A138736,A138739,A138743,A138744,A138745,A138746,A138750,A138751,A138756,A138763,A138764,A138765,A138773,A138778,A138779,A138780,A138784,A138785,A138786,A138791,A138792,A138793,A138794,A138795,A138796,A138797,A138798,A138799,A138800,A138804,A138805,A138806,A138807,A138808,A138809,A138810,A138811,A138812,A138816,A138817,A138821,A138822,A138829,A138830,A138831,A138832,A138833,A138834,A138835,A138836,A138840,A138841,A138842,A138843,A138844,A138845,A138846,A138847,A138848,A138849,A138850"
				let parentSystemIds = _.uniq(
					assetListForUserSites.map(
						(a: any) =>
							this.dataService.cache.assetsObject[a.Id]
								.ParentSystemId
					)
				);
				let parentSystemIdsString = parentSystemIds
					.map((a: any) => 'PS' + a.toString())
					.join();

				let jbtStandardObservationIdsAsString: any =
					jbtStandardObservationIds?.toString();
				// need a list of all the asset id's for all teh sites as a string, comma seperated.
				this.dataService
					.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds(
						siteAssetIds,
						false,
						jbtStandardObservationIdsAsString
					)
					.subscribe((data: any) => {
						let tagSettings: any;
						if (data === true) {
							let tagList = [];
							assetListForUserSites.forEach((asset) =>
								asset.Tags.forEach((t) => tagList.push(t.TagId))
							);
							tagSettings = {
								WidgetId: widgetObject.WidgetId,
								TagList: tagList,
								IsPopup: false,
							};
						} else if (data) {
							tagSettings = {
								WidgetId: widgetObject.WidgetId,
								TagList: data.map((a: any) => a.Id),
								IsPopup: false,
							};
						}

						assetListForUserSites.forEach((asset) => {
							asset.displayName = asset.Name;
							let evaluationObject: any =
								this.determineAssetImageFromAssetTypeForTable(
									asset
								);
							let evaluationObjectForUserDefinedAlarms: any =
								this.determineUserDefinedAlarmsImageFromAssetTypeForTable(
									asset
								);
							if (
								evaluationObject.icon !== undefined
							) {
								asset.stringPathForAssetImage =
									evaluationObject.icon.url;
							} else {
								asset.stringPathForAssetImage = '';
							}
							if (
								evaluationObjectForUserDefinedAlarms.alarmType !==
									'' &&
								evaluationObjectForUserDefinedAlarms.icon !==
									undefined
							) {
								asset.stringPathForAssetUserDefinedAlarm =
									evaluationObjectForUserDefinedAlarms.icon.url;
							} else {
								asset.stringPathForAssetUserDefinedAlarm = '';
							}
							_.forEach(columnDefinitionArray, (column) => {
								if (column.fieldName === 'driveMinutesToday') {
									console.log('hit');
								}
								if (column.fieldName === 'power') {
									let returnedId = asset.Tags.findIndex(
										(tagInstance) => {
											return (
												tagInstance.JBTStandardObservationId ==
												column.columnObId
											);
										}
									);

									let optionalPower = asset.Tags.findIndex(
										(tagInstance) => {
											return (
												tagInstance.JBTStandardObservationId ==
												57478
											);
										}
									);

									if (
										returnedId !== -1 &&
										+asset.Tags[returnedId].Value === 0
									) {
										asset[column.fieldName] = false;
									} else if (
										returnedId !== -1 &&
										+asset.Tags[returnedId].Value === 1
									) {
										asset[column.fieldName] = true;
									} else if (
										optionalPower !== -1 &&
										+asset.Tags[optionalPower].Value === 0
									) {
										asset[column.fieldName] = false;
									} else if (
										optionalPower !== -1 &&
										+asset.Tags[optionalPower].Value === 1
									) {
										asset[column.fieldName] = true;
									} else {
										asset[column.fieldName] = null;
									}
								} else if (column.fieldName === 'engineOn') {
									let returnedId = asset.Tags.findIndex(
										(tagInstance) => {
											return (
												tagInstance.JBTStandardObservationId ==
												column.columnObId
											);
										}
									);

									let engineOn = asset.Tags.findIndex(
										(tagInstance) => {
											return (
												tagInstance.JBTStandardObservationId ==
												57478
											);
										}
									);

									if (
										returnedId !== -1 &&
										+asset.Tags[returnedId].Value === 0
									) {
										asset[column.fieldName] = false;
									} else if (
										returnedId !== -1 &&
										+asset.Tags[returnedId].Value === 1
									) {
										asset[column.fieldName] = true;
									} else if (
										engineOn !== -1 &&
										+asset.Tags[engineOn].Value === 0
									) {
										asset[column.fieldName] = false;
									} else if (
										engineOn !== -1 &&
										+asset.Tags[engineOn].Value === 1
									) {
										asset[column.fieldName] = true;
									} else {
										asset[column.fieldName] = null;
									}
								} else if (
									column.fieldName === 'driveMode' ||
									column.fieldName === 'operateMode' ||
									column.fieldName === 'maintenance' ||
									column.fieldName === 'AirFirstActive' ||
									column.fieldName === 'OneManDrive' ||
									column.fieldName === 'OneManDriveTrigger' ||
									column.fieldName === 'BoomOverrideRs' ||
									column.fieldName === 'BoomOverrideTg' ||
									column.fieldName === 'EngineOverrideFuel' ||
									column.fieldName ===
										'EngineOverrideLowFuel' ||
									column.fieldName === 'BasketSeatBelt' ||
									column.fieldName === 'lowFuelLevel'
								) {
									let returnedId = asset.Tags.findIndex(
										(tagInstance) => {
											return (
												tagInstance.JBTStandardObservationId ==
												column.columnObId
											);
										}
									);
									if (
										returnedId !== -1 &&
										+asset.Tags[returnedId].Value === 0
									) {
										asset[column.fieldName] = false;
									} else if (
										returnedId !== -1 &&
										+asset.Tags[returnedId].Value === 1
									) {
										asset[column.fieldName] = true;
									} else {
										asset[column.fieldName] = null;
									}
								} else if (column.convertedFieldName) {
									let returnedId = asset.Tags.findIndex(
										(tagInstance) => {
											return (
												tagInstance.JBTStandardObservationId ==
												column.columnObId
											);
										}
									);

									if (returnedId !== -1) {
										let calcNum =
											+asset.Tags[returnedId].Value / 60;
										asset[column.fieldName] =
											+calcNum.toFixed(2);
									} else {
										asset[column.fieldName] = null;
									}
								} else if (column.fieldName === 'fuelLevel') {
									//doing this because fuel level tags are coming in as strings and numbers, so the sorting on that column is not working correctly.
									if (column.columnObId === 19141) {
										let tag =
											asset.Tags[
												asset.Tags.findIndex(
													(tagInstance) => {
														return (
															tagInstance.JBTStandardObservationId ==
															column.columnObId
														);
													}
												)
											];
									}
									let retrievedValue =
										asset.Tags.findIndex((tagInstance) => {
											return (
												tagInstance.JBTStandardObservationId ==
												column.columnObId
											);
										}) !== -1
											? asset.Tags[
													asset.Tags.findIndex(
														(tagInstance) => {
															return (
																tagInstance.JBTStandardObservationId ==
																column.columnObId
															);
														}
													)
											  ].Value
											: null;
									let optionalFuelLevel =
										asset.Tags.findIndex((tagInstance) => {
											return (
												tagInstance.JBTStandardObservationId ==
												19212
											);
										}) !== -1
											? asset.Tags[
													asset.Tags.findIndex(
														(tagInstance) => {
															return (
																tagInstance.JBTStandardObservationId ==
																19212
															);
														}
													)
											  ].Value
											: null;
									if (
										retrievedValue !== -1 &&
										retrievedValue !== null
									) {
										asset[column.fieldName] =
											parseInt(retrievedValue);
									} else if (
										optionalFuelLevel !== -1 &&
										optionalFuelLevel !== null
									) {
										asset[column.fieldName] =
											parseInt(optionalFuelLevel);
									} else {
										asset[column.fieldName] = null;
									}
								} else if (
									column.fieldName ===
									'DeiceFluidTankLevelPercent'
								) {
									let retrievedValue =
										asset.Tags.findIndex((tagInstance) => {
											return (
												tagInstance.JBTStandardObservationId ==
												column.columnObId
											);
										}) !== -1
											? asset.Tags[
													asset.Tags.findIndex(
														(tagInstance) => {
															return (
																tagInstance.JBTStandardObservationId ==
																column.columnObId
															);
														}
													)
											  ].Value
											: null;
									let optionalFluidTankLevelPercent =
										asset.Tags.findIndex((tagInstance) => {
											return (
												tagInstance.JBTStandardObservationId ==
												56349
											);
										}) !== -1
											? asset.Tags[
													asset.Tags.findIndex(
														(tagInstance) => {
															return (
																tagInstance.JBTStandardObservationId ==
																56349
															);
														}
													)
											  ].Value
											: null;
									if (
										retrievedValue !== -1 &&
										retrievedValue !== null
									) {
										asset[column.fieldName] =
											parseInt(retrievedValue);
									} else if (
										optionalFluidTankLevelPercent !== -1 &&
										optionalFluidTankLevelPercent !== null
									) {
										asset[column.fieldName] = parseInt(
											optionalFluidTankLevelPercent
										);
									} else {
										asset[column.fieldName] = null;
									}
								} else if (
									column.fieldName === 'AntiIceTankPercent'
								) {
									let retrievedValue =
										asset.Tags.findIndex((tagInstance) => {
											return (
												tagInstance.JBTStandardObservationId ==
												column.columnObId
											);
										}) !== -1
											? asset.Tags[
													asset.Tags.findIndex(
														(tagInstance) => {
															return (
																tagInstance.JBTStandardObservationId ==
																column.columnObId
															);
														}
													)
											  ].Value
											: null;
									let optionalFluidTankLevelPercent =
										asset.Tags.findIndex((tagInstance) => {
											return (
												tagInstance.JBTStandardObservationId ==
												56350
											);
										}) !== -1
											? asset.Tags[
													asset.Tags.findIndex(
														(tagInstance) => {
															return (
																tagInstance.JBTStandardObservationId ==
																56350
															);
														}
													)
											  ].Value
											: null;
									if (
										retrievedValue !== -1 &&
										retrievedValue !== null
									) {
										asset[column.fieldName] =
											parseInt(retrievedValue);
									} else if (
										optionalFluidTankLevelPercent !== -1 &&
										optionalFluidTankLevelPercent !== null
									) {
										asset[column.fieldName] = parseInt(
											optionalFluidTankLevelPercent
										);
									} else {
										asset[column.fieldName] = null;
									}
								} else if (
									column.fieldName ===
									'DeiceFluidTankLevelLiters'
								) {
									let retrievedValue =
										asset.Tags.findIndex((tagInstance) => {
											return (
												tagInstance.JBTStandardObservationId ==
												column.columnObId
											);
										}) !== -1
											? asset.Tags[
													asset.Tags.findIndex(
														(tagInstance) => {
															return (
																tagInstance.JBTStandardObservationId ==
																column.columnObId
															);
														}
													)
											  ].Value
											: null;
									let optionalFluidTankLevelLiters =
										asset.Tags.findIndex((tagInstance) => {
											return (
												tagInstance.JBTStandardObservationId ==
												57483
											);
										}) !== -1
											? asset.Tags[
													asset.Tags.findIndex(
														(tagInstance) => {
															return (
																tagInstance.JBTStandardObservationId ==
																57483
															);
														}
													)
											  ].Value
											: null;
									if (
										retrievedValue !== -1 &&
										retrievedValue !== null &&
										parseInt(retrievedValue) !== 65535 &&
										parseInt(retrievedValue) !== 0
									) {
										asset[column.fieldName] =
											parseInt(retrievedValue);
									} else if (
										optionalFluidTankLevelLiters !== -1 &&
										optionalFluidTankLevelLiters !== null &&
										parseInt(
											optionalFluidTankLevelLiters
										) !== 65535 &&
										parseInt(
											optionalFluidTankLevelLiters
										) !== 0
									) {
										asset[column.fieldName] = parseInt(
											optionalFluidTankLevelLiters
										);
									} else {
										asset[column.fieldName] = null;
									}
								} else if (
									column.fieldName ===
									'AntiIceTankVolumeLiters'
								) {
									let retrievedValue =
										asset.Tags.findIndex((tagInstance) => {
											return (
												tagInstance.JBTStandardObservationId ==
												column.columnObId
											);
										}) !== -1
											? asset.Tags[
													asset.Tags.findIndex(
														(tagInstance) => {
															return (
																tagInstance.JBTStandardObservationId ==
																column.columnObId
															);
														}
													)
											  ].Value
											: null;
									let optionalAntiIceFluidTankLevelLiters =
										asset.Tags.findIndex((tagInstance) => {
											return (
												tagInstance.JBTStandardObservationId ==
												57484
											);
										}) !== -1
											? asset.Tags[
													asset.Tags.findIndex(
														(tagInstance) => {
															return (
																tagInstance.JBTStandardObservationId ==
																57484
															);
														}
													)
											  ].Value
											: null;
									if (
										retrievedValue !== -1 &&
										retrievedValue !== null &&
										parseInt(retrievedValue) !== 65535 &&
										parseInt(retrievedValue) !== 0
									) {
										asset[column.fieldName] =
											parseInt(retrievedValue);
									} else if (
										optionalAntiIceFluidTankLevelLiters !==
											-1 &&
										optionalAntiIceFluidTankLevelLiters !==
											null &&
										parseInt(
											optionalAntiIceFluidTankLevelLiters
										) !== 65535 &&
										parseInt(
											optionalAntiIceFluidTankLevelLiters
										) !== 0
									) {
										asset[column.fieldName] = parseInt(
											optionalAntiIceFluidTankLevelLiters
										);
									} else {
										asset[column.fieldName] = null;
									}
								} else if (
									column.fieldName ===
										'CatalystDEFTankLevel' ||
									column.fieldName === 'EngineHours' ||
									column.fieldName === 'FuelConsumption' ||
									column.fieldName === 'ActiveFaults' ||
									column.fieldName === 'AmbientTempBasket' ||
									column.fieldName === 'HeaterTempInlet' ||
									column.fieldName === 'HeaterTempOutlet' ||
									column.fieldName === 'HeaterTempNozzle' ||
									column.fieldName === 'engineMinutesToday' ||
									column.fieldName === 'idleTime' ||
									column.fieldName ===
										'totalEngineRunTimeHrs' ||
									column.fieldName === 'driveMinutesToday' ||
									column.fieldName === 'idleMinutesToday' ||
									column.fieldName === 'minutesOperatedToday'
								) {
									//doing this because fuel level tags are coming in as strings and numbers, so the sorting on that column is not working correctly.
									let retrievedValue =
										asset.Tags.findIndex((tagInstance) => {
											return (
												tagInstance.JBTStandardObservationId ==
												column.columnObId
											);
										}) !== -1
											? asset.Tags[
													asset.Tags.findIndex(
														(tagInstance) => {
															return (
																tagInstance.JBTStandardObservationId ==
																column.columnObId
															);
														}
													)
											  ].Value
											: null;

									if (
										retrievedValue !== -1 &&
										retrievedValue !== null &&
										retrievedValue !== undefined
									) {
										asset[column.fieldName] =
											parseInt(retrievedValue);
									} else {
										asset[column.fieldName] = null;
									}
									if (
										column.fieldName === 'idleMinutesToday'
									) {
										console.log(asset[column.fieldName]);
									}
								} else if (column.columnObId !== undefined) {
									let retrievedValue = (asset[
										column.fieldName
									] =
										asset.Tags.findIndex((tagInstance) => {
											return (
												tagInstance.JBTStandardObservationId ==
												column.columnObId
											);
										}) !== -1
											? asset.Tags[
													asset.Tags.findIndex(
														(tagInstance) => {
															return (
																tagInstance.JBTStandardObservationId ==
																column.columnObId
															);
														}
													)
											  ].Value
											: null);
									asset[column.fieldName] = retrievedValue;
								}
							});
						});

						if (widgetObject.WidgetKendoUIJson) {
							let jsonObjectParsed =
								this.kendoSettingsService.parseReturnedSettingsForDates(
									widgetObject.WidgetKendoUIJson
								);
							//the first item in the array is the gridSettings for the first tab of data for GSE-Overview

							let returnedParsedObject = jsonObjectParsed[0];
							//We pass it into the function in the kendo service to compare what has been saved vs the template declaration of columns to make sure they get the lastest updates.
							gridSettings = this.mapGridSettings(
								this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(
									gridSettings,
									returnedParsedObject
								),
								assetListForUserSites
							);
							gridSettings.gridData = process(
								assetListForUserSites,
								gridSettings.state
							);
							this.dashboardService.addOrUpdateHiddenColumnCountToDashboardWidget(
								[gridSettings.columnsConfig],
								widgetObject.WidgetId
							);
						} else {
							gridSettings.gridData = process(
								assetListForUserSites,
								gridSettings.state
							);
						}
						// this.gridSettings.gridData = process(this.assetListForUserSites, this.gridSettings.state);
						resolve({
							gridSettings: gridSettings,
							signalRAssetIds: signalRAssetIds,
							tagSettings: tagSettings,
							assetListForUserSites: assetListForUserSites,
							parentSystemIdsString: parentSystemIdsString,
						});
					});
			}
		});
	}

	beginBuildingArrayDataForFleetGenericComponents(
		widgetObject,
		assetTypeIds,
		columnDefinitionArray,
		jbtStandardObservationIds,
		gridSettings
	): any {
		return new Promise((resolve, reject) => {
			let assetListForUserSites = [];
			let fleetId;

			if (!_.isNil(widgetObject.VocationalSettingsJSON)) {
				var VocationalSettings = JSON.parse(widgetObject.VocationalSettingsJSON);
				Global.User.DebugMode && console.log("VocationalSettings ", VocationalSettings)

				fleetId = VocationalSettings.id;
			}

			this.dataService.GetFleetAssets().subscribe((data) => {
				let assetIdListArray = new Array();
				data.forEach(record => {
					if(record.FleetId == fleetId) {
						assetIdListArray.push(Number(record.AssetId));
					}
				});

				assetListForUserSites = this.dataService.cache.assets.filter(
					(asset) => {
						return assetIdListArray.includes(asset.Id) && assetTypeIds.includes(asset.AssetTypeId);
					}
				);

				if (assetListForUserSites.length === 0) {
					resolve('');
				} else {
					let siteAssetIds: any = assetListForUserSites
						.map((a) => a.Id.toString())
						.join();
					let signalRAssetIds: any = assetListForUserSites
						.map((a: any) => 'A' + a.Id.toString())
						.join(); //-- Asset groups must have the letter 'A' in front of the assetId to join the groups in the SignalR hub. --Kirk T. Sherer, November 8, 2021. ex "A137885,A137886,A137887,A137888,A137890,A137891,A137892,A137893,A137894,A137914,A137915,A137916,A137917,A137931,A137942,A137978,A137990,A137992,A138295,A138296,A138297,A138298,A138305,A138310,A138311,A138312,A138313,A138314,A138315,A138316,A138318,A138321,A138343,A138345,A138346,A138374,A138375,A138376,A138377,A138378,A138403,A138404,A138405,A138406,A138407,A138408,A138409,A138410,A138411,A138412,A138413,A138414,A138417,A138438,A138440,A138441,A138444,A138448,A138449,A138451,A138452,A138453,A138508,A138509,A138510,A138511,A138512,A138513,A138514,A138515,A138521,A138526,A138527,A138528,A138529,A138540,A138541,A138542,A138545,A138556,A138560,A138561,A138562,A138563,A138564,A138565,A138566,A138568,A138579,A138580,A138581,A138582,A138583,A138638,A138639,A138640,A138641,A138644,A138650,A138657,A138675,A138676,A138680,A138684,A138685,A138686,A138700,A138713,A138714,A138717,A138718,A138720,A138724,A138726,A138732,A138735,A138736,A138739,A138743,A138744,A138745,A138746,A138750,A138751,A138756,A138763,A138764,A138765,A138773,A138778,A138779,A138780,A138784,A138785,A138786,A138791,A138792,A138793,A138794,A138795,A138796,A138797,A138798,A138799,A138800,A138804,A138805,A138806,A138807,A138808,A138809,A138810,A138811,A138812,A138816,A138817,A138821,A138822,A138829,A138830,A138831,A138832,A138833,A138834,A138835,A138836,A138840,A138841,A138842,A138843,A138844,A138845,A138846,A138847,A138848,A138849,A138850"
					let parentSystemIds = _.uniq(
						assetListForUserSites.map(
							(a: any) =>
								this.dataService.cache.assetsObject[a.Id]
									.ParentSystemId
						)
					);
					let parentSystemIdsString = parentSystemIds
						.map((a: any) => 'PS' + a.toString())
						.join();

					let jbtStandardObservationIdsAsString: any =
						jbtStandardObservationIds?.toString();
					// need a list of all the asset id's for all teh sites as a string, comma seperated.
					this.dataService
						.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds(
							siteAssetIds,
							false,
							jbtStandardObservationIdsAsString
						)
						.subscribe((data: any) => {
							let tagSettings: any;
							if (data === true) {
								let tagList = [];
								assetListForUserSites.forEach((asset) =>
									asset.Tags.forEach((t) => tagList.push(t.TagId))
								);
								tagSettings = {
									WidgetId: widgetObject.WidgetId,
									TagList: tagList,
									IsPopup: false,
								};
							} else if (data) {
								tagSettings = {
									WidgetId: widgetObject.WidgetId,
									TagList: data.map((a: any) => a.Id),
									IsPopup: false,
								};
							}

							assetListForUserSites.forEach((asset) => {
								asset.displayName = asset.Name;
								asset.FleetName = widgetObject.FleetName;
								let evaluationObject: any =
									this.determineAssetImageFromAssetTypeForTable(
										asset
									);
								let evaluationObjectForUserDefinedAlarms: any =
									this.determineUserDefinedAlarmsImageFromAssetTypeForTable(
										asset
									);
								if (
									evaluationObject.icon !== undefined
								) {
									asset.stringPathForAssetImage =
										evaluationObject.icon.url;
								} else {
									asset.stringPathForAssetImage = '';
								}
								if (
									evaluationObjectForUserDefinedAlarms.alarmType !==
										'' &&
									evaluationObjectForUserDefinedAlarms.icon !==
										undefined
								) {
									asset.stringPathForAssetUserDefinedAlarm =
										evaluationObjectForUserDefinedAlarms.icon.url;
								} else {
									asset.stringPathForAssetUserDefinedAlarm = '';
								}
								_.forEach(columnDefinitionArray, (column) => {
									if (column.fieldName === 'driveMinutesToday') {
										console.log('hit');
									}
									if (column.fieldName === 'power') {
										let returnedId = asset.Tags.findIndex(
											(tagInstance) => {
												return (
													tagInstance.JBTStandardObservationId ==
													column.columnObId
												);
											}
										);

										let optionalPower = asset.Tags.findIndex(
											(tagInstance) => {
												return (
													tagInstance.JBTStandardObservationId ==
													57478
												);
											}
										);

										if (
											returnedId !== -1 &&
											+asset.Tags[returnedId].Value === 0
										) {
											asset[column.fieldName] = false;
										} else if (
											returnedId !== -1 &&
											+asset.Tags[returnedId].Value === 1
										) {
											asset[column.fieldName] = true;
										} else if (
											optionalPower !== -1 &&
											+asset.Tags[optionalPower].Value === 0
										) {
											asset[column.fieldName] = false;
										} else if (
											optionalPower !== -1 &&
											+asset.Tags[optionalPower].Value === 1
										) {
											asset[column.fieldName] = true;
										} else {
											asset[column.fieldName] = null;
										}
									} else if (column.fieldName === 'totalSeatbelts'){
										//find total amount of seatbelts and return value
									}

									else if (column.fieldName === 'engineOn') {
										let returnedId = asset.Tags.findIndex(
											(tagInstance) => {
												return (
													tagInstance.JBTStandardObservationId ==
													column.columnObId
												);
											}
										);

										let engineOn = asset.Tags.findIndex(
											(tagInstance) => {
												return (
													tagInstance.JBTStandardObservationId ==
													57478
												);
											}
										);

										if (
											returnedId !== -1 &&
											+asset.Tags[returnedId].Value === 0
										) {
											asset[column.fieldName] = false;
										} else if (
											returnedId !== -1 &&
											+asset.Tags[returnedId].Value === 1
										) {
											asset[column.fieldName] = true;
										} else if (
											engineOn !== -1 &&
											+asset.Tags[engineOn].Value === 0
										) {
											asset[column.fieldName] = false;
										} else if (
											engineOn !== -1 &&
											+asset.Tags[engineOn].Value === 1
										) {
											asset[column.fieldName] = true;
										} else {
											asset[column.fieldName] = null;
										}
									} else if (
										column.fieldName === 'driveMode' ||
										column.fieldName === 'operateMode' ||
										column.fieldName === 'maintenance' ||
										column.fieldName === 'AirFirstActive' ||
										column.fieldName === 'OneManDrive' ||
										column.fieldName === 'OneManDriveTrigger' ||
										column.fieldName === 'BoomOverrideRs' ||
										column.fieldName === 'BoomOverrideTg' ||
										column.fieldName === 'EngineOverrideFuel' ||
										column.fieldName ===
											'EngineOverrideLowFuel' ||
										column.fieldName === 'BasketSeatBelt' ||
										column.fieldName === 'lowFuelLevel'
									) {
										let returnedId = asset.Tags.findIndex(
											(tagInstance) => {
												return (
													tagInstance.JBTStandardObservationId ==
													column.columnObId
												);
											}
										);
										if (
											returnedId !== -1 &&
											+asset.Tags[returnedId].Value === 0
										) {
											asset[column.fieldName] = false;
										} else if (
											returnedId !== -1 &&
											+asset.Tags[returnedId].Value === 1
										) {
											asset[column.fieldName] = true;
										} else {
											asset[column.fieldName] = null;
										}
									} else if (column.convertedFieldName) {
										let returnedId = asset.Tags.findIndex(
											(tagInstance) => {
												return (
													tagInstance.JBTStandardObservationId ==
													column.columnObId
												);
											}
										);

										if (returnedId !== -1) {
											let calcNum =
												+asset.Tags[returnedId].Value / 60;
											asset[column.fieldName] =
												+calcNum.toFixed(2);
										} else {
											asset[column.fieldName] = null;
										}
									} else if (column.fieldName === 'fuelLevel') {
										//doing this because fuel level tags are coming in as strings and numbers, so the sorting on that column is not working correctly.
										if (column.columnObId === 19141) {
											let tag =
												asset.Tags[
													asset.Tags.findIndex(
														(tagInstance) => {
															return (
																tagInstance.JBTStandardObservationId ==
																column.columnObId
															);
														}
													)
												];
										}
										let retrievedValue =
											asset.Tags.findIndex((tagInstance) => {
												return (
													tagInstance.JBTStandardObservationId ==
													column.columnObId
												);
											}) !== -1
												? asset.Tags[
														asset.Tags.findIndex(
															(tagInstance) => {
																return (
																	tagInstance.JBTStandardObservationId ==
																	column.columnObId
																);
															}
														)
												].Value
												: null;
										let optionalFuelLevel =
											asset.Tags.findIndex((tagInstance) => {
												return (
													tagInstance.JBTStandardObservationId ==
													19212
												);
											}) !== -1
												? asset.Tags[
														asset.Tags.findIndex(
															(tagInstance) => {
																return (
																	tagInstance.JBTStandardObservationId ==
																	19212
																);
															}
														)
												].Value
												: null;
										if (
											retrievedValue !== -1 &&
											retrievedValue !== null
										) {
											asset[column.fieldName] =
												parseInt(retrievedValue);
										} else if (
											optionalFuelLevel !== -1 &&
											optionalFuelLevel !== null
										) {
											asset[column.fieldName] =
												parseInt(optionalFuelLevel);
										} else {
											asset[column.fieldName] = null;
										}
									} else if (
										column.fieldName ===
										'DeiceFluidTankLevelPercent'
									) {
										let retrievedValue =
											asset.Tags.findIndex((tagInstance) => {
												return (
													tagInstance.JBTStandardObservationId ==
													column.columnObId
												);
											}) !== -1
												? asset.Tags[
														asset.Tags.findIndex(
															(tagInstance) => {
																return (
																	tagInstance.JBTStandardObservationId ==
																	column.columnObId
																);
															}
														)
												].Value
												: null;
										let optionalFluidTankLevelPercent =
											asset.Tags.findIndex((tagInstance) => {
												return (
													tagInstance.JBTStandardObservationId ==
													56349
												);
											}) !== -1
												? asset.Tags[
														asset.Tags.findIndex(
															(tagInstance) => {
																return (
																	tagInstance.JBTStandardObservationId ==
																	56349
																);
															}
														)
												].Value
												: null;
										if (
											retrievedValue !== -1 &&
											retrievedValue !== null
										) {
											asset[column.fieldName] =
												parseInt(retrievedValue);
										} else if (
											optionalFluidTankLevelPercent !== -1 &&
											optionalFluidTankLevelPercent !== null
										) {
											asset[column.fieldName] = parseInt(
												optionalFluidTankLevelPercent
											);
										} else {
											asset[column.fieldName] = null;
										}
									} else if (
										column.fieldName === 'AntiIceTankPercent'
									) {
										let retrievedValue =
											asset.Tags.findIndex((tagInstance) => {
												return (
													tagInstance.JBTStandardObservationId ==
													column.columnObId
												);
											}) !== -1
												? asset.Tags[
														asset.Tags.findIndex(
															(tagInstance) => {
																return (
																	tagInstance.JBTStandardObservationId ==
																	column.columnObId
																);
															}
														)
												].Value
												: null;
										let optionalFluidTankLevelPercent =
											asset.Tags.findIndex((tagInstance) => {
												return (
													tagInstance.JBTStandardObservationId ==
													56350
												);
											}) !== -1
												? asset.Tags[
														asset.Tags.findIndex(
															(tagInstance) => {
																return (
																	tagInstance.JBTStandardObservationId ==
																	56350
																);
															}
														)
												].Value
												: null;
										if (
											retrievedValue !== -1 &&
											retrievedValue !== null
										) {
											asset[column.fieldName] =
												parseInt(retrievedValue);
										} else if (
											optionalFluidTankLevelPercent !== -1 &&
											optionalFluidTankLevelPercent !== null
										) {
											asset[column.fieldName] = parseInt(
												optionalFluidTankLevelPercent
											);
										} else {
											asset[column.fieldName] = null;
										}
									} else if (
										column.fieldName ===
										'DeiceFluidTankLevelLiters'
									) {
										let retrievedValue =
											asset.Tags.findIndex((tagInstance) => {
												return (
													tagInstance.JBTStandardObservationId ==
													column.columnObId
												);
											}) !== -1
												? asset.Tags[
														asset.Tags.findIndex(
															(tagInstance) => {
																return (
																	tagInstance.JBTStandardObservationId ==
																	column.columnObId
																);
															}
														)
												].Value
												: null;
										let optionalFluidTankLevelLiters =
											asset.Tags.findIndex((tagInstance) => {
												return (
													tagInstance.JBTStandardObservationId ==
													57483
												);
											}) !== -1
												? asset.Tags[
														asset.Tags.findIndex(
															(tagInstance) => {
																return (
																	tagInstance.JBTStandardObservationId ==
																	57483
																);
															}
														)
												].Value
												: null;
										if (
											retrievedValue !== -1 &&
											retrievedValue !== null &&
											parseInt(retrievedValue) !== 65535 &&
											parseInt(retrievedValue) !== 0
										) {
											asset[column.fieldName] =
												parseInt(retrievedValue);
										} else if (
											optionalFluidTankLevelLiters !== -1 &&
											optionalFluidTankLevelLiters !== null &&
											parseInt(
												optionalFluidTankLevelLiters
											) !== 65535 &&
											parseInt(
												optionalFluidTankLevelLiters
											) !== 0
										) {
											asset[column.fieldName] = parseInt(
												optionalFluidTankLevelLiters
											);
										} else {
											asset[column.fieldName] = null;
										}
									} else if (
										column.fieldName ===
										'AntiIceTankVolumeLiters'
									) {
										let retrievedValue =
											asset.Tags.findIndex((tagInstance) => {
												return (
													tagInstance.JBTStandardObservationId ==
													column.columnObId
												);
											}) !== -1
												? asset.Tags[
														asset.Tags.findIndex(
															(tagInstance) => {
																return (
																	tagInstance.JBTStandardObservationId ==
																	column.columnObId
																);
															}
														)
												].Value
												: null;
										let optionalAntiIceFluidTankLevelLiters =
											asset.Tags.findIndex((tagInstance) => {
												return (
													tagInstance.JBTStandardObservationId ==
													57484
												);
											}) !== -1
												? asset.Tags[
														asset.Tags.findIndex(
															(tagInstance) => {
																return (
																	tagInstance.JBTStandardObservationId ==
																	57484
																);
															}
														)
												].Value
												: null;
										if (
											retrievedValue !== -1 &&
											retrievedValue !== null &&
											parseInt(retrievedValue) !== 65535 &&
											parseInt(retrievedValue) !== 0
										) {
											asset[column.fieldName] =
												parseInt(retrievedValue);
										} else if (
											optionalAntiIceFluidTankLevelLiters !==
												-1 &&
											optionalAntiIceFluidTankLevelLiters !==
												null &&
											parseInt(
												optionalAntiIceFluidTankLevelLiters
											) !== 65535 &&
											parseInt(
												optionalAntiIceFluidTankLevelLiters
											) !== 0
										) {
											asset[column.fieldName] = parseInt(
												optionalAntiIceFluidTankLevelLiters
											);
										} else {
											asset[column.fieldName] = null;
										}
									} else if (
										column.fieldName ===
											'CatalystDEFTankLevel' ||
										column.fieldName === 'EngineHours' ||
										column.fieldName === 'FuelConsumption' ||
										column.fieldName === 'ActiveFaults' ||
										column.fieldName === 'AmbientTempBasket' ||
										column.fieldName === 'HeaterTempInlet' ||
										column.fieldName === 'HeaterTempOutlet' ||
										column.fieldName === 'HeaterTempNozzle' ||
										column.fieldName === 'engineMinutesToday' ||
										column.fieldName === 'idleTime' ||
										column.fieldName ===
											'totalEngineRunTimeHrs' ||
										column.fieldName === 'driveMinutesToday' ||
										column.fieldName === 'idleMinutesToday' ||
										column.fieldName === 'minutesOperatedToday'
									) {
										//doing this because fuel level tags are coming in as strings and numbers, so the sorting on that column is not working correctly.
										let retrievedValue =
											asset.Tags.findIndex((tagInstance) => {
												return (
													tagInstance.JBTStandardObservationId ==
													column.columnObId
												);
											}) !== -1
												? asset.Tags[
														asset.Tags.findIndex(
															(tagInstance) => {
																return (
																	tagInstance.JBTStandardObservationId ==
																	column.columnObId
																);
															}
														)
												].Value
												: null;

										if (
											retrievedValue !== -1 &&
											retrievedValue !== null &&
											retrievedValue !== undefined
										) {
											asset[column.fieldName] =
												parseInt(retrievedValue);
										} else {
											asset[column.fieldName] = null;
										}
										if (
											column.fieldName === 'idleMinutesToday'
										) {
											console.log(asset[column.fieldName]);
										}
									} else if (column.columnObId !== undefined) {
										let retrievedValue = (asset[
											column.fieldName
										] =
											asset.Tags.findIndex((tagInstance) => {
												return (
													tagInstance.JBTStandardObservationId ==
													column.columnObId
												);
											}) !== -1
												? asset.Tags[
														asset.Tags.findIndex(
															(tagInstance) => {
																return (
																	tagInstance.JBTStandardObservationId ==
																	column.columnObId
																);
															}
														)
												].Value
												: null);
										asset[column.fieldName] = retrievedValue;
									}
								});
							});

							if (widgetObject.WidgetKendoUIJson) {
								let jsonObjectParsed =
									this.kendoSettingsService.parseReturnedSettingsForDates(
										widgetObject.WidgetKendoUIJson
									);
								//the first item in the array is the gridSettings for the first tab of data for GSE-Overview

								let returnedParsedObject = jsonObjectParsed[0];
								//We pass it into the function in the kendo service to compare what has been saved vs the template declaration of columns to make sure they get the lastest updates.
								gridSettings = this.mapGridSettings(
									this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(
										gridSettings,
										returnedParsedObject
									),
									assetListForUserSites
								);
								gridSettings.gridData = process(
									assetListForUserSites,
									gridSettings.state
								);
								this.dashboardService.addOrUpdateHiddenColumnCountToDashboardWidget(
									[gridSettings.columnsConfig],
									widgetObject.WidgetId
								);
							} else {
								gridSettings.gridData = process(
									assetListForUserSites,
									gridSettings.state
								);
							}
							// this.gridSettings.gridData = process(this.assetListForUserSites, this.gridSettings.state);
							resolve({
								gridSettings: gridSettings,
								signalRAssetIds: signalRAssetIds,
								tagSettings: tagSettings,
								assetListForUserSites: assetListForUserSites,
								parentSystemIdsString: parentSystemIdsString,
							});
						});
				}
			});
		});
	}

	public mapGridSettings(gridSettings: any, assetListForUserSites: any): any {
		const state = gridSettings.state;
		let emptyArray: any = [];
		return {
			state,
			columnsConfig: gridSettings.columnsConfig.sort(
				(a, b) => a.orderIndex - b.orderIndex
			),
			gridData:
				this.dataService.cache !== undefined
					? process(assetListForUserSites, state)
					: emptyArray,
		};
	}

	updateTagGridData(tagObj: ITag, assetListForUserSites:any, columnDefinitionArray: any) {
		if(tagObj == null) {
			//Global.User.DebugMode && console.log("tag not found in cache", tagObjFromSignalR);
			return;
		}
		let columnWhereObservationidMatchesTag = columnDefinitionArray.find(
			(column) => {
				return tagObj.JBTStandardObservationId === column.columnObId;
			}
		);
		if (!_.isNil(columnWhereObservationidMatchesTag)) {
			if (
				assetListForUserSites !== undefined &&
				assetListForUserSites.length > 0
			) {
				let index = assetListForUserSites.findIndex((assetInList) => {
					return assetInList.Id === tagObj.AssetId;
				});
				if (index !== -1) {
					let evaluationObject: any =
						this.determineAssetImageFromAssetTypeForTable(
							assetListForUserSites[index]
						);
					let evaluationObjectForUserDefinedAlarms: any =
						this.determineUserDefinedAlarmsImageFromAssetTypeForTable(
							assetListForUserSites[index]
						);

					if (
						evaluationObject.icon !== undefined
					) {
						assetListForUserSites[index].stringPathForAssetImage =
							evaluationObject.icon.url;
					} else {
						assetListForUserSites[index].stringPathForAssetImage =
							'';
					}
					if (
						evaluationObjectForUserDefinedAlarms.alarmType !== '' &&
						evaluationObjectForUserDefinedAlarms.icon !== undefined
					) {
						assetListForUserSites[
							index
						].stringPathForAssetUserDefinedAlarm =
							evaluationObjectForUserDefinedAlarms.icon.url;
					} else {
						assetListForUserSites[
							index
						].stringPathForAssetUserDefinedAlarm = '';
					}

					if (
						columnWhereObservationidMatchesTag.fieldName === 'power'
					) {
						let returnedId = assetListForUserSites[
							index
						].Tags.findIndex((tagInstance) => {
							return (
								tagInstance.JBTStandardObservationId ==
								columnWhereObservationidMatchesTag.columnObId
							);
						});
						let optionalPower = assetListForUserSites[
							index
						].Tags.findIndex((tagInstance) => {
							return (
								tagInstance.JBTStandardObservationId == 57478
							);
						});
						if (
							returnedId !== -1 &&
							+assetListForUserSites[index].Tags[returnedId]
								.Value === 0
						) {
							assetListForUserSites[index][
								columnWhereObservationidMatchesTag.fieldName
							] = false;
						} else if (
							returnedId !== -1 &&
							+assetListForUserSites[index].Tags[returnedId]
								.Value === 1
						) {
							assetListForUserSites[index][
								columnWhereObservationidMatchesTag.fieldName
							] = true;
						} else if (
							optionalPower !== -1 &&
							+assetListForUserSites[index].Tags[optionalPower]
								.Value === 0
						) {
							assetListForUserSites[index][
								columnWhereObservationidMatchesTag.fieldName
							] = false;
						} else if (
							optionalPower !== -1 &&
							+assetListForUserSites[index].Tags[optionalPower]
								.Value === 1
						) {
							assetListForUserSites[index][
								columnWhereObservationidMatchesTag.fieldName
							] = true;
						} else {
							assetListForUserSites[index][
								columnWhereObservationidMatchesTag.fieldName
							] = null;
						}
					} else if (
						columnWhereObservationidMatchesTag.fieldName ===
						'engineOn'
					) {
						let returnedId = assetListForUserSites[
							index
						].Tags.findIndex((tagInstance) => {
							return (
								tagInstance.JBTStandardObservationId ==
								columnWhereObservationidMatchesTag.columnObId
							);
						});
						let optionalPower = assetListForUserSites[
							index
						].Tags.findIndex((tagInstance) => {
							return (
								tagInstance.JBTStandardObservationId == 57478
							);
						});
						if (
							returnedId !== -1 &&
							+assetListForUserSites[index].Tags[returnedId]
								.Value === 0
						) {
							assetListForUserSites[index][
								columnWhereObservationidMatchesTag.fieldName
							] = false;
						} else if (
							returnedId !== -1 &&
							+assetListForUserSites[index].Tags[returnedId]
								.Value === 1
						) {
							assetListForUserSites[index][
								columnWhereObservationidMatchesTag.fieldName
							] = true;
						} else if (
							optionalPower !== -1 &&
							+assetListForUserSites[index].Tags[optionalPower]
								.Value === 0
						) {
							assetListForUserSites[index][
								columnWhereObservationidMatchesTag.fieldName
							] = false;
						} else if (
							optionalPower !== -1 &&
							+assetListForUserSites[index].Tags[optionalPower]
								.Value === 1
						) {
							assetListForUserSites[index][
								columnWhereObservationidMatchesTag.fieldName
							] = true;
						} else {
							assetListForUserSites[index][
								columnWhereObservationidMatchesTag.fieldName
							] = null;
						}
					} else if (
						columnWhereObservationidMatchesTag.fieldName ===
							'driveMode' ||
						columnWhereObservationidMatchesTag.fieldName ===
							'operateMode' ||
						columnWhereObservationidMatchesTag.fieldName ===
							'maintenance' ||
						columnWhereObservationidMatchesTag.fieldName ===
							'AirFirstActive' ||
						columnWhereObservationidMatchesTag.fieldName ===
							'OneManDrive' ||
						columnWhereObservationidMatchesTag.fieldName ===
							'OneManDriveTrigger' ||
						columnWhereObservationidMatchesTag.fieldName ===
							'BoomOverrideRs' ||
						columnWhereObservationidMatchesTag.fieldName ===
							'BoomOverrideTg' ||
						columnWhereObservationidMatchesTag.fieldName ===
							'EngineOverrideFuel' ||
						columnWhereObservationidMatchesTag.fieldName ===
							'EngineOverrideLowFuel' ||
						columnWhereObservationidMatchesTag.fieldName ===
							'BasketSeatBelt' ||
						columnWhereObservationidMatchesTag.fieldName ===
							'lowFuelLevel'
					) {
						let returnedId = assetListForUserSites[
							index
						].Tags.findIndex((tagInstance) => {
							return (
								tagInstance.JBTStandardObservationId ==
								columnWhereObservationidMatchesTag.columnObId
							);
						});
						if (
							returnedId !== -1 &&
							+assetListForUserSites[index].Tags[returnedId]
								.Value === 0
						) {
							assetListForUserSites[index][
								columnWhereObservationidMatchesTag.fieldName
							] = false;
						} else if (
							returnedId !== -1 &&
							+assetListForUserSites[index].Tags[returnedId]
								.Value === 1
						) {
							assetListForUserSites[index][
								columnWhereObservationidMatchesTag.fieldName
							] = true;
						} else {
							assetListForUserSites[index][
								columnWhereObservationidMatchesTag.fieldName
							] = null;
						}
					} else if (
						columnWhereObservationidMatchesTag.convertedFieldName
					) {
						let returnedId = assetListForUserSites[
							index
						].Tags.findIndex((tagInstance) => {
							return (
								tagInstance.JBTStandardObservationId ==
								columnWhereObservationidMatchesTag.columnObId
							);
						});

						if (returnedId !== -1) {
							let calcNum =
								+assetListForUserSites[index].Tags[returnedId]
									.Value / 60;
							assetListForUserSites[index][
								columnWhereObservationidMatchesTag.fieldName
							] = +calcNum.toFixed(2);
						} else {
							if (
								columnWhereObservationidMatchesTag.fieldName ===
								'DeiceFluidTankLevelPercent'
							) {
								let retrievedValue =
									assetListForUserSites[index].Tags.findIndex(
										(tagInstance) => {
											return (
												tagInstance.JBTStandardObservationId ==
												columnWhereObservationidMatchesTag.columnObId
											);
										}
									) !== -1
										? assetListForUserSites[index].Tags[
												assetListForUserSites[
													index
												].Tags.findIndex(
													(tagInstance) => {
														return (
															tagInstance.JBTStandardObservationId ==
															columnWhereObservationidMatchesTag.columnObId
														);
													}
												)
										  ].Value
										: null;
								let optionalFluidTankLevelPercent =
									assetListForUserSites[index].Tags.findIndex(
										(tagInstance) => {
											return (
												tagInstance.JBTStandardObservationId ==
												56349
											);
										}
									) !== -1
										? assetListForUserSites[index].Tags[
												assetListForUserSites[
													index
												].Tags.findIndex(
													(tagInstance) => {
														return (
															tagInstance.JBTStandardObservationId ==
															56349
														);
													}
												)
										  ].Value
										: null;
								if (
									retrievedValue !== -1 &&
									retrievedValue !== null
								) {
									assetListForUserSites[index][
										columnWhereObservationidMatchesTag.fieldName
									] = parseInt(retrievedValue);
								} else if (
									optionalFluidTankLevelPercent !== -1 &&
									optionalFluidTankLevelPercent !== null
								) {
									assetListForUserSites[index][
										columnWhereObservationidMatchesTag.fieldName
									] = parseInt(optionalFluidTankLevelPercent);
								} else {
									assetListForUserSites[index][
										columnWhereObservationidMatchesTag.fieldName
									] = null;
								}
							} else if (
								columnWhereObservationidMatchesTag.fieldName ===
								'AntiIceTankPercent'
							) {
								let retrievedValue =
									assetListForUserSites[index].Tags.findIndex(
										(tagInstance) => {
											return (
												tagInstance.JBTStandardObservationId ==
												columnWhereObservationidMatchesTag.columnObId
											);
										}
									) !== -1
										? assetListForUserSites[index].Tags[
												assetListForUserSites[
													index
												].Tags.findIndex(
													(tagInstance) => {
														return (
															tagInstance.JBTStandardObservationId ==
															columnWhereObservationidMatchesTag.columnObId
														);
													}
												)
										  ].Value
										: null;
								let optionalFluidTankLevelPercent =
									assetListForUserSites[index].Tags.findIndex(
										(tagInstance) => {
											return (
												tagInstance.JBTStandardObservationId ==
												56350
											);
										}
									) !== -1
										? assetListForUserSites[index].Tags[
												assetListForUserSites[
													index
												].Tags.findIndex(
													(tagInstance) => {
														return (
															tagInstance.JBTStandardObservationId ==
															56350
														);
													}
												)
										  ].Value
										: null;
								if (
									retrievedValue !== -1 &&
									retrievedValue !== null
								) {
									assetListForUserSites[index][
										columnWhereObservationidMatchesTag.fieldName
									] = parseInt(retrievedValue);
								} else if (
									optionalFluidTankLevelPercent !== -1 &&
									optionalFluidTankLevelPercent !== null
								) {
									assetListForUserSites[index][
										columnWhereObservationidMatchesTag.fieldName
									] = parseInt(optionalFluidTankLevelPercent);
								} else {
									assetListForUserSites[index][
										columnWhereObservationidMatchesTag.fieldName
									] = null;
								}
							}
						}
					} else if (
						columnWhereObservationidMatchesTag.fieldName ===
						'fuelLevel'
					) {
						//doing this because fuel level tags are coming in as strings and numbers, so the sorting on that column is not working correctly.
						let retrievedValue =
							assetListForUserSites[index].Tags.findIndex(
								(tagInstance) => {
									return (
										tagInstance.JBTStandardObservationId ==
										columnWhereObservationidMatchesTag.columnObId
									);
								}
							) !== -1
								? assetListForUserSites[index].Tags[
										assetListForUserSites[
											index
										].Tags.findIndex((tagInstance) => {
											return (
												tagInstance.JBTStandardObservationId ==
												columnWhereObservationidMatchesTag.columnObId
											);
										})
								  ].Value
								: null;
						let optionalFuelLevel =
							assetListForUserSites[index].Tags.findIndex(
								(tagInstance) => {
									return (
										tagInstance.JBTStandardObservationId ==
										19212
									);
								}
							) !== -1
								? assetListForUserSites[index].Tags[
										assetListForUserSites[
											index
										].Tags.findIndex((tagInstance) => {
											return (
												tagInstance.JBTStandardObservationId ==
												19212
											);
										})
								  ].Value
								: null;
						if (retrievedValue !== -1 && retrievedValue !== null) {
							assetListForUserSites[index][
								columnWhereObservationidMatchesTag.fieldName
							] = parseInt(retrievedValue);
						} else if (
							optionalFuelLevel !== -1 &&
							optionalFuelLevel !== null
						) {
							assetListForUserSites[index][
								columnWhereObservationidMatchesTag.fieldName
							] = parseInt(optionalFuelLevel);
						} else {
							assetListForUserSites[index][
								columnWhereObservationidMatchesTag.fieldName
							] = null;
						}
					} else if (
						columnWhereObservationidMatchesTag.fieldName ===
						'DeiceFluidTankLevelLiters'
					) {
						let retrievedValue =
							assetListForUserSites[index].Tags.findIndex(
								(tagInstance) => {
									return (
										tagInstance.JBTStandardObservationId ==
										columnWhereObservationidMatchesTag.columnObId
									);
								}
							) !== -1
								? assetListForUserSites[index].Tags[
										assetListForUserSites[
											index
										].Tags.findIndex((tagInstance) => {
											return (
												tagInstance.JBTStandardObservationId ==
												columnWhereObservationidMatchesTag.columnObId
											);
										})
								  ].Value
								: null;
						let optionalFluidTankLevelLiters =
							assetListForUserSites[index].Tags.findIndex(
								(tagInstance) => {
									return (
										tagInstance.JBTStandardObservationId ==
										57483
									);
								}
							) !== -1
								? assetListForUserSites[index].Tags[
										assetListForUserSites[
											index
										].Tags.findIndex((tagInstance) => {
											return (
												tagInstance.JBTStandardObservationId ==
												57483
											);
										})
								  ].Value
								: null;
						if (
							retrievedValue !== -1 &&
							retrievedValue !== null &&
							parseInt(retrievedValue) !== 65535 &&
							parseInt(retrievedValue) !== 0
						) {
							assetListForUserSites[index][
								columnWhereObservationidMatchesTag.fieldName
							] = parseInt(retrievedValue);
						} else if (
							optionalFluidTankLevelLiters !== -1 &&
							optionalFluidTankLevelLiters !== null &&
							parseInt(optionalFluidTankLevelLiters) !== 65535 &&
							parseInt(optionalFluidTankLevelLiters) !== 0
						) {
							assetListForUserSites[index][
								columnWhereObservationidMatchesTag.fieldName
							] = parseInt(optionalFluidTankLevelLiters);
						} else {
							assetListForUserSites[index][
								columnWhereObservationidMatchesTag.fieldName
							] = null;
						}
					} else if (
						columnWhereObservationidMatchesTag.fieldName ===
						'AntiIceTankVolumeLiters'
					) {
						let retrievedValue =
							assetListForUserSites[index].Tags.findIndex(
								(tagInstance) => {
									return (
										tagInstance.JBTStandardObservationId ==
										columnWhereObservationidMatchesTag.columnObId
									);
								}
							) !== -1
								? assetListForUserSites[index].Tags[
										assetListForUserSites[
											index
										].Tags.findIndex((tagInstance) => {
											return (
												tagInstance.JBTStandardObservationId ==
												columnWhereObservationidMatchesTag.columnObId
											);
										})
								  ].Value
								: null;
						let optionalAntiIceFluidTankLevelLiters =
							assetListForUserSites[index].Tags.findIndex(
								(tagInstance) => {
									return (
										tagInstance.JBTStandardObservationId ==
										57484
									);
								}
							) !== -1
								? assetListForUserSites[index].Tags[
										assetListForUserSites[
											index
										].Tags.findIndex((tagInstance) => {
											return (
												tagInstance.JBTStandardObservationId ==
												57484
											);
										})
								  ].Value
								: null;
						if (
							retrievedValue !== -1 &&
							retrievedValue !== null &&
							parseInt(retrievedValue) !== 65535 &&
							parseInt(retrievedValue) !== 0
						) {
							assetListForUserSites[index][
								columnWhereObservationidMatchesTag.fieldName
							] = parseInt(retrievedValue);
						} else if (
							optionalAntiIceFluidTankLevelLiters !== -1 &&
							optionalAntiIceFluidTankLevelLiters !== null &&
							parseInt(optionalAntiIceFluidTankLevelLiters) !==
								65535 &&
							parseInt(optionalAntiIceFluidTankLevelLiters) !== 0
						) {
							assetListForUserSites[index][
								columnWhereObservationidMatchesTag.fieldName
							] = parseInt(optionalAntiIceFluidTankLevelLiters);
						} else {
							assetListForUserSites[index][
								columnWhereObservationidMatchesTag.fieldName
							] = null;
						}
					} else if (
						columnWhereObservationidMatchesTag.fieldName ===
							'CatalystDEFTankLevel' ||
						columnWhereObservationidMatchesTag.fieldName ===
							'EngineHours' ||
						columnWhereObservationidMatchesTag.fieldName ===
							'FuelConsumption' ||
						columnWhereObservationidMatchesTag.fieldName ===
							'ActiveFaults' ||
						columnWhereObservationidMatchesTag.fieldName ===
							'AmbientTempBasket' ||
						columnWhereObservationidMatchesTag.fieldName ===
							'HeaterTempInlet' ||
						columnWhereObservationidMatchesTag.fieldName ===
							'HeaterTempOutlet' ||
						columnWhereObservationidMatchesTag.fieldName ===
							'HeaterTempNozzle'
					) {
						//doing this because fuel level tags are coming in as strings and numbers, so the sorting on that column is not working correctly.
						let retrievedValue =
							assetListForUserSites[index].Tags.findIndex(
								(tagInstance) => {
									return (
										tagInstance.JBTStandardObservationId ==
										columnWhereObservationidMatchesTag.columnObId
									);
								}
							) !== -1
								? assetListForUserSites[index].Tags[
										assetListForUserSites[
											index
										].Tags.findIndex((tagInstance) => {
											return (
												tagInstance.JBTStandardObservationId ==
												columnWhereObservationidMatchesTag.columnObId
											);
										})
								  ].Value
								: null;

						if (retrievedValue !== -1 && retrievedValue !== null) {
							assetListForUserSites[index][
								columnWhereObservationidMatchesTag.fieldName
							] = parseInt(retrievedValue);
						} else {
							assetListForUserSites[index][
								columnWhereObservationidMatchesTag.fieldName
							] = null;
						}
					} else if (
						columnWhereObservationidMatchesTag.columnObId !==
						undefined
					) {
						let retrievedValue = (assetListForUserSites[index][
							columnWhereObservationidMatchesTag.fieldName
						] =
							assetListForUserSites[index].Tags.findIndex(
								(tagInstance) => {
									return (
										tagInstance.JBTStandardObservationId ==
										columnWhereObservationidMatchesTag.columnObId
									);
								}
							) !== -1
								? assetListForUserSites[index].Tags[
										assetListForUserSites[
											index
										].Tags.findIndex((tagInstance) => {
											return (
												tagInstance.JBTStandardObservationId ==
												columnWhereObservationidMatchesTag.columnObId
											);
										})
								  ].Value
								: null);
						assetListForUserSites[index][
							columnWhereObservationidMatchesTag.fieldName
						] = retrievedValue;
					}
					return assetListForUserSites;
				} else {
					return assetListForUserSites;
				}
			}
		} else {
			return assetListForUserSites;
		}
	}

	seatStatus(dataItem, index, dataWantReturned){
		//dataWantReturned can be occupied or engaged
		// dataItem.totalSeatbelts gives me total seatbelts
		//lets say there are 4 total seatbelts and index = 0
		//we need to determine if the seatbelt is clicked or nto

		//if occupied
		//find if the seat is occupied
		//if occupied:
		//return green seatbelt image src url
		//else:
		//return grey seatbelt image src url

		//if engaged
		//find if the seatbelt is engaged
		//if engaged:
		//return green seatbelt image src url
		//else:
		//return red seatbelt image src url

		//let seatbelt = this.dataService.cache.assetsObject(dataItem.Id).Tags.find(tag => find tag that is reference to seatbelt clicked status);
		//if(seatbelt.Value === 1) {
			//return green seatbelt image src url
		//} else {
			//return red seatbelt image src url
		//}
	}
}
