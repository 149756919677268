<div class="content" style="height: calc(100%);">
	<div class="card" [style.height]="global.isMobile ? 'calc(100%)' : 'calc(100vh - 95px)'" style="width: calc(100%)">
		<div
			class="card-header user-settings"
			style="
				display: flex;
				min-height: 0px !important;
				padding: 0px !important
			"
		>
			<h5
				class="title d-inline"
				style="display: inline"
				[style.font-size]="'14px'"
				style="padding: 6px 0px 0px 6px !important"
			>
				What's new with iOPS?
			</h5>
			<div
				style="
					padding: 0px;
					float: right;
					margin-right: 20px;
					margin-left: 20px;
					margin-left: auto;
				"
			>
				<div>
					<button mat-button [matMenuTriggerFor]="menu">
						<i class="tim-icons icon-settings-gear-63"> </i>
					</button>
					<mat-menu #menu="matMenu">

						<button
							(click)="closeAndStopSeeingReleaseNotesAtLogin()"
							mat-menu-item
						>
							Don't show at login until next release
						</button>
						<button
							(click)="closeAndStopSeeingReleaseNotesAtLogin(1)"
							mat-menu-item
						>
							Don't show at login permanently
						</button>
					</mat-menu>
				</div>
			</div>
		</div>
		<div
			class="card-body user-settings release-notes-body"
			style="overflow-y: scroll !important"
		>
			<div style="height: 100%">
				<div *ngFor="let version of distinctVersions">
					<div
						class="version-name"
						[style.font-size]="global.isMobile ? '12px' : '14px'"
					>
						v <strong>{{ version.name }}</strong>
					</div>
					<div
						class="version-description"
						[style.font-size]="global.isMobile ? '12px' : '14px'"
					>
						{{ version.description }} -
						<span
							class="environment-description"
							[style.font-size]="global.isMobile ? '12px' : '14px'"
							>in {{ version.environment
							}}{{
								version.goLiveDate != null &&
								version.environment == "Production"
									? " on " + version.goLiveDate
									: ""
							}}</span
						>
					</div>
					<h4 *ngIf="version.releaseNoteFeatures.length > 0" style="text-transform:uppercase"><u>Features</u></h4>
					<ul class="unorderedList">

						<li
							*ngFor="let note of version.releaseNoteFeatures"
							[style.font-size]="global.isMobile ? '12px' : '14px'"
						>
							<div
								[style.font-size]="
									global.isMobile ? '12px' : '14px'
								"
							>
								<strong
									>{{ note.name
									}}{{
										note.internal ? " (internal)" : ""
									}} </strong
								>:
							</div>
							<div
								[style.font-size]="
									global.isMobile ? '12px' : '14px'
								"
							>
								{{ note.description }}
							</div>

								<div
									class="text-center view-file"
									(click)="viewPicture(note.uploadedPicture)"
									*ngIf="note.uploadedPicture != null"
								>
									<div >
										<div *ngIf="note.uploadedPicture.viewUrl">
											<img
												alt="photo"
												src="{{
													note.uploadedPicture.viewUrl
												}}"
												title="{{
													note.uploadedPicture.name
												}}"
											/>
										</div>

									</div>
								</div>
								&nbsp;

						</li>

					</ul>
					<h4 *ngIf="version.releaseNoteBugs.length > 0" style="text-transform:uppercase"><u>Bug Fixes</u></h4>
					<ul class="unorderedList">
						<li
							*ngFor="let note of version.releaseNoteBugs"
							[style.font-size]="global.isMobile ? '12px' : '14px'"
						>
							<div
								[style.font-size]="
									global.isMobile ? '12px' : '14px'
								"
							>
								<strong
									>{{ note.name
									}}{{
										note.internal ? " (internal)" : ""
									}} </strong
								>:
							</div>
							<div
								[style.font-size]="
									global.isMobile ? '12px' : '14px'
								"
							>
								{{ note.description }}
							</div>

								<div
									class="text-center view-file"
									(click)="viewPicture(note.uploadedPicture)"
									*ngIf="note.uploadedPicture != null"
								>
									<div >
										<div *ngIf="note.uploadedPicture.viewUrl">
											<img
												alt="photo"
												src="{{
													note.uploadedPicture.viewUrl
												}}"
												title="{{
													note.uploadedPicture.name
												}}"
											/>
										</div>

									</div>
								</div>
								&nbsp;

						</li>
					</ul>

						<h4 *ngIf="version.releaseNoteNotes.length > 0" style="text-transform:uppercase"><u>Notes</u></h4>
						<ul class="unorderedList">
							<li
							*ngFor="let note of version.releaseNoteNotes"
							[style.font-size]="global.isMobile ? '12px' : '14px'"
						>
							<div
								[style.font-size]="
									global.isMobile ? '12px' : '14px'
								"
							>
								<strong
									>{{ note.name
									}}{{
										note.internal ? " (internal)" : ""
									}} </strong
								>:
							</div>
							<div
								[style.font-size]="
									global.isMobile ? '12px' : '14px'
								"
							>
								{{ note.description }}
							</div>

								<div
									class="text-center view-file"
									(click)="viewPicture(note.uploadedPicture)"
									*ngIf="note.uploadedPicture != null"
								>
									<div >
										<div *ngIf="note.uploadedPicture.viewUrl">
											<img
												alt="photo"
												src="{{
													note.uploadedPicture.viewUrl
												}}"
												title="{{
													note.uploadedPicture.name
												}}"
											/>
										</div>

									</div>
								</div>
								&nbsp;

						</li>
						</ul>

				</div>
			</div>
		</div>
	</div>
</div>

